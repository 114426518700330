















































































































































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { CompetenceProfileType, LegendType } from '@/store/types'
import CompetenceProfileValueField from '@/components/forms/CompetenceProfileValueField.vue'
import ColorLegend from '@/components/common/ColorLegend.vue'

@Component({
  components: { ColorLegend, CompetenceProfileValueField }
})
// Displays the competence profile (8 kode values + difference and average).
// Used for the talent in their profile and the employee which inputs the values form the KODE metering.
// @group KODE
export default class CompetenceProfile extends Vue {
  // Contains A, P, F and S values in favorable and unfavorable conditions plus some other data (check the type)
  @Prop({ required: true })
  value: CompetenceProfileType

  // Is it editable? Used to distinguish between talent and employee
  @Prop({ required: true })
  editable: boolean

  @Watch('value')
  onValueChange (newValue: CompetenceProfileType): void {
    this.internalModel = newValue
  }

  @Watch('internalModel')
  onInternalModelChange (): void {
    this.$emit('input', this.internalModel)
  }

  internalModel: CompetenceProfileType = {}

  get legend (): LegendType[] {
    return [
      { text: this.$i18n.t('kode.info.range-1').toString(), color: this.$colors.kode12to22 },
      { text: this.$i18n.t('kode.info.range-2').toString(), color: this.$colors.kode23to34 },
      { text: this.$i18n.t('kode.info.range-3').toString(), color: this.$colors.kode35to40 },
      { text: this.$i18n.t('kode.info.range-4').toString(), color: this.$colors.kode41to48 }
    ]
  }

  mounted (): void {
    this.internalModel = this.value
  }

  // get average of competence values (round in direction of favorable if necessary)
  getAverage (fav: number | string, unfav: number | string): number | null {
    if (fav && unfav) {
      fav = parseInt(fav.toString())
      unfav = parseInt(unfav.toString())
      let average = (fav + unfav) / 2
      if (!Number.isInteger(average)) {
        if (fav < unfav) average = Math.floor(average)
        else average = Math.ceil(average)
      }
      if (Number.isNaN(average)) return null
      else return average
    } else {
      return null
    }
  }

  // get difference of competence values
  getDifference (fav: number, unfav: number): number | null {
    if (!fav || !unfav) return null
    else return unfav - fav
  }
}
