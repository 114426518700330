

















import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {}
})
/*
Used in the CompetenceProfile it represents a single input field for a single KODE profile value
@group FORMS
 */
export default class CompetenceProfileValueField extends Vue {
  // like the v-model
  @Prop({ required: true })
  value: number

  // unique identifier
  @Prop({ required: false })
  id: string

  // whether the input field is for the difference value (disables it)
  @Prop({ required: false })
  difference: boolean

  // whether the input field is for the average value (disables it)
  @Prop({ required: false })
  average: boolean

  // whether the input field should be disabled
  @Prop({ required: false })
  disabled: boolean

  @Watch('value')
  onValueChange (newValue: string): void {
    this.internalValue = newValue
  }

  internalValue = ''

  get valueColor (): string {
    let color = 'black-white'

    if (!this.difference) {
      if (this.value >= 12 && this.value <= 22) color = 'white color1'
      else if (this.value >= 23 && this.value <= 34) color = 'white color2'
      else if (this.value >= 35 && this.value <= 40) color = 'white color3'
      else if (this.value >= 41 && this.value <= 48) color = 'white color4'
    }

    return color
  }

  mounted (): void {
    if (this.value) this.internalValue = this.value.toString()
  }

  keyHandler (event: KeyboardEvent): void {
    // when the keypressed is no number
    if (event.key) {
      if (event.key.length !== 1 || !(event.key >= '0' && event.key <= '9')) {
        event.preventDefault()
      }
    } else {
      // usage of deprecated property charcode as a fallback
      // noinspection JSDeprecatedSymbols
      if (!(event.charCode >= 48 && event.charCode <= 57)) {
        event.preventDefault()
      }
    }
  }

  validateAndEmit (): void {
    if (this.internalValue.length > 2) {
      this.internalValue = this.internalValue.slice(0, 2)
    }
    if (Number.isNaN(parseInt(this.internalValue))) {
      this.internalValue = ''
    } else {
      if (parseInt(this.internalValue) > 48) {
        this.internalValue = '48'
      }
    }
    this.$emit('input', this.internalValue === '' ? '' : parseInt(this.internalValue))
  }
}
